.buttonWrapper {
    display: inline-block;
  }
  
  .button {
    cursor: pointer;
    background: rgb(196, 195, 195);
    color: rgb(46, 46, 46);
    font-size: 18px;
    border: 0;
    padding-top: 5px;
    vertical-align: bottom;
    height: 34px;
    width: 36px;
    border-radius: 4px;
    margin: 0.25rem;
  }
  
  .button svg {
    fill: rgb(46, 46, 46);
  }
  
  .button:hover, .button:focus {
    background: #444;
    outline: 0; 
  }
  
  .active {
    color: #6a9cc9;
  }
  
  .active svg {
    fill: #6a9cc9;
  }
  