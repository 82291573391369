.toolbar {
  border: 1px solid rgb(184, 184, 184);
  background: rgb(216, 216, 216);
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
  z-index: 1;
  box-sizing: border-box;
  margin-top: 0.5rem;
}

.toolbar:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #333;
  border-width: 4px;
  margin-left: -4px;
}

.toolbar:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #111;
  border-width: 6px;
  margin-left: -6px;
}
