.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 1em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  max-width: 1070px;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.editor :global(.public-DraftEditor-content) .headlineButtonWrapper {
  display: inline-block;
}

.editor :global(.public-DraftEditor-content) div blockquote {
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  font-family: 'Montserrat';
  line-height: 23px;
  border-left: 3px solid #28969f;
  color: #1a1a1a;
  background-color: #d8d8d8;
  margin-left: 1rem;
}

.editor :global(.public-DraftEditor-content) div a {
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Montserrat';
  line-height: 23px;
  color: #b9335a;
}

.editor :global(.public-DraftEditor-content) div a span span {
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Montserrat';
  line-height: 23px;
  color: #b9335a !important;
}

.editor :global(.public-DraftEditor-content) div h1 {
  font-style: bold;
  font-weight: 700;
  font-size: 60px;
  font-family: 'Montserrat';
  display: none;
  margin-bottom: 1rem;
}

.editor :global(.public-DraftEditor-content) div h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  font-family: 'Montserrat';
  color: #b9335a;
  margin-bottom: 1rem;
}

.editor :global(.public-DraftEditor-content) div h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  font-family: 'Montserrat';
  color: #b9335a;
  line-height: 22px;
  margin-bottom: 1rem;
}

.editor :global(.public-DraftEditor-content) div div div {
  margin-bottom: 0.5rem;
  white-space: 'normal';
}

.editor :global(.public-DraftEditor-content) div div div span {
  font-size: 16px;
  font-family: 'Montserrat';
  line-height: 23px;
  color: #222222;
}

.editor :global(.public-DraftEditor-content) div blockquote div span span {
  color: #2e2e2e;
}

.editor :global(.public-DraftEditor-content) div figure table {
  border-spacing: 0;
  border: 1px solid #adadad;
}

.editor :global(.public-DraftEditor-content) div figure table td {
  border-spacing: 0;
  border: 1px solid #adadad;
}

.editor :global(.public-DraftEditor-content) div figure table tr {
  border-spacing: 0;
  border: 1px solid #adadad;
}

.editor
  :global(.public-DraftEditor-content)
  div
  :global(.editor-alignment-left) {
  text-align: left;
}

.editor :global(.public-DraftEditor-content) div figure {
  text-align: center !important;
}

.editor :global(.public-DraftEditor-content) div iframe {
  text-align: center !important;
}

.editor
  :global(.public-DraftEditor-content)
  div
  :global(.editor-alignment-center) {
  text-align: center !important;
}

.editor
  :global(.public-DraftEditor-content)
  div
  :global(.editor-alignment-right) {
  text-align: right !important;
}

.editor
  :global(.public-DraftEditor-content)
  div
  :global(.editor-alignment-left)
  * {
  text-align: inherit;
}

.editor
  :global(.public-DraftEditor-content)
  div
  :global(.editor-alignment-center)
  * {
  text-align: inherit;
}

.editor
  :global(.public-DraftEditor-content)
  div
  :global(.editor-alignment-right)
  * {
  text-align: inherit;
}

.headlineButton {
  cursor: pointer;
  background: #fbfbfb;
  color: rgb(46, 46, 46);
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.headlineButton:hover,
.headlineButton:focus {
  background: #f3f3f3;
}
